export default () => ({
    isLoading: true,
    isLoadingPend: true,
    atletas: [],
    atletasReserva: [],
    countAtletas: 0,
    countReserva: 0,
    countCat: 0,
    countCatReserva: 0,
    eventos: [],
    categorias: [],
    categoriasReserva: [],
    eventoscategorias: [],
    selectedEvento: {},
    config: {
        config: {
            header: {},
            images: [],
            slider: []
        }
    },
    configPage: [],
    paginate: {
        current_page: '',
        from: '',
        last_page: '',
        per_page: '',
        prev_page_url: '',
        to: '',
        total: ''
    },
    search: '',
    page404: false
})