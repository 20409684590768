import state from './State'
import * as actions from './Actions'
import * as mutations from './Mutations'
import * as getters from './Getters'

const atletasModule = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}


export default atletasModule