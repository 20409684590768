
export const consultarCarrerasGetter = (state) => {
    return state.carrerasAbiertas
}

export const consultarCarrerasTokenGetter = (state) => {
    return state.carreraToken
}

export const getAtletas = (state) => {
    return state.atletas
}

export const getAtletaSelect = (state) => {
    return state.atletaseleccionado
}

export const getBusId = (state) => (id) => {
    const atleta = state.atletas.find(atleta => atleta.id == id)
    if (!atleta) return
    return { ...atleta }
}

//atletas bloqueados
export const getBusBloqueados = (state) => (id = '') => {
    let atletas = []
    for (let i = 0; i < state.atletas.length; i++) {
        if (state.atletas[i].bloqueado === '1') {
            atletas.push(state.atletas[i])
        }
    }
    if (atletas.length > 0) {
        return atletas
    } else {
        return []
    }


    /*const atleta = state.atletas.find(atleta => atleta.bloqueado == '1')
    if (!atleta) return []
    return atleta*/
}

//atletas inscritos
export const getBusInscritos = (state) => (id = '') => {
    let atletas = []
    for (let i = 0; i < state.atletas.length; i++) {
        if (state.atletas[i].validar_pago === '1' && state.atletas[i].bloqueado === '0') {
            atletas.push(state.atletas[i])
        }
    }
    if (atletas.length > 0) {
        return atletas
    } else {
        return []
    }

    /*const atleta = state.atletas.find(atleta => atleta.validar_pago == '1')
    if (!atleta) return []
    return atleta*/
}
//atletas preinscritos
export const getBusPreinscritos = (state) => (id = '') => {
    let atletas = []
    for (let i = 0; i < state.atletas.length; i++) {
        if (state.atletas[i].validar_pago === '0' && state.atletas[i].bloqueado === '0') {
            atletas.push(state.atletas[i])
        }
    }
    if (atletas.length > 0) {
        return atletas
    } else {
        return []
    }

    /*const atleta = state.atletas.find(atleta => atleta.validar_pago == '0')
    if (!atleta) return []
    return atleta*/
}



export const getBuscatInscripciones = (state) => (search = '') => {
    if (search.length === 0) return state.atletas
    let atletas = []
    if (search.length > 0) {
        for (let i = 0; i < state.atletas.length; i++) {
            if (state.atletas[i].bloqueado === '0') {
                if (state.atletas[i].nombres.toLowerCase().includes(search.toLowerCase())) {
                    atletas.push(state.atletas[i])
                } else if (search.toString().toLowerCase().includes('k')) {
                    if (state.atletas[i].distancia.includes(search.toString().toLowerCase())) {
                        atletas.push(state.atletas[i])
                    }
                } else if (!isNaN(search)) {
                    if (state.atletas[i].cedula_rif.includes(search)) {
                        atletas.push(state.atletas[i])
                    } else {
                        if (state.atletas[i].nrocorredor.includes(search)) {
                            atletas.push(state.atletas[i])
                        }
                    }
                } else {
                }
            }
        }
        if (atletas.length > 0) {
            return atletas
        } else {
            return []
        }
    }
}

//categorias
export const getCategorias = (state) => {
    return state.categorias
}

export const getBuscatCategorias = (state) => (search = '') => {
    if (search.length === 0) return state.categorias
    let categorias = []
    if (search.length > 0) {
        for (let i = 0; i < state.categorias.length; i++) {
            if (state.categorias[i].nombre.toLowerCase().includes(search.toLowerCase())) {
                categorias.push(state.categorias[i])
            } else if (state.categorias[i].descripcion.toLowerCase().includes(search.toLowerCase())) {
                categorias.push(state.categorias[i])
            } else {

            }
        }
        if (categorias.length > 0) {
            console.log(categorias)
            return categorias
        } else {
            return []
        }
    }
}
//franelas
export const getFranelas = (state) => {
    return state.franelas
}

export const getBuscatFranelas = (state) => (search = '') => {
    if (search.length === 0) return state.franelas
    let franelas = []
    if (search.length > 0) {
        for (let i = 0; i < state.franelas.length; i++) {
            if (state.franelas[i].nombre.toLowerCase().includes(search.toLowerCase())) {
                franelas.push(state.franelas[i])
            } else if (state.franelas[i].descripcion.toLowerCase().includes(search.toLowerCase())) {
                franelas.push(state.franelas[i])
            } else {

            }
        }
        if (franelas.length > 0) {
            console.log(franelas)
            return franelas
        } else {
            return []
        }
    }
}


//pagos
export const getPago = (state) => {
    return state.pago
}