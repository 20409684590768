
import axios from 'axios'


const registerApi = axios.create({
    baseURL: 'https://apipurocrono.chdesarrolloweb.com'
})


export default registerApi


