import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { createHead } from '@vueuse/head'
const head = createHead()

import vTitle from 'vuejs-title'

import '@/assets/styles.css'
import './assets/js.js'
createApp(App)
    .use(router)
    .use(store)
    .use(head)
    .use(vTitle)
    .mount('#app')
