
import axios from 'axios'


const registerApi = axios.create({
    baseURL: 'https://api.purocrono.com'
})


export default registerApi


