
import axios from 'axios'


const eventosApi = axios.create({
    baseURL: 'https://apipurocrono.chdesarrolloweb.com'
    // baseURL: 'http://localhost:8000/'
    // baseURL: 'http://localhost:8000/'
})


export default eventosApi


