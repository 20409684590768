export default () => ({
    isLoading: true,
    isLoadingPend: true,
    carrerasAbiertas: [],
    carreraToken: {},
    tokenUser: {},
    atletasInscritos: [],
    pendientes: [],
    pago: {},
    atletas: [],
    evento: {},
    categoria: {},
    categorias: [],
    camisas: [],
    franela: {},
    franelas: [],
    atletaseleccionado: {},
    vaciar: false
    /*atletas: [],
    atletasReserva: [],
    eventos: [],
    categorias: [],
    eventoscategorias: [],
    selectedEvento: {},
    config: {
        config: {
            header: {},
            images: [],
            slider: []
        }
    },
    configPage: [],
    paginate: {
        current_page: '',
        from: '',
        last_page: '',
        per_page: '',
        prev_page_url: '',
        to: '',
        total: ''
    },
    search: '',
    page404: false*/
})