<template>
  <Preloader v-if="isLoading" />
  <HeaderPincipal />
  <!-- <HeaderPincipal v-if="!this.$route.params.slug"/>
  <HeaderBuscador v-else/> -->

  <router-view />

  <FooterPincipal />




  <!-- <h1 v-if="authStatus === 'authenticating'" >{{ authStatus }}</h1>
  <router-view v-else /> -->
</template>


<script>
import { defineAsyncComponent } from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'AppHome',
  data() {
    return {
    }
  },
  components: {
    Preloader: defineAsyncComponent(() => import('@/modules/web/Components/Preloader.vue')),
    HeaderPincipal: defineAsyncComponent(() => import('@/modules/web/Components/Header.vue')),
    /*HeaderBuscador: defineAsyncComponent(() => import('@/modules/web/Components/HeaderBuscador.vue')),*/
    FooterPincipal: defineAsyncComponent(() => import('@/modules/web/Components/Footer.vue'))
  },
  methods: {

  },
  computed: {
    ...mapState('atletas', ['isLoading']),
  },
  created() {

  }
}


/*
import useAuth from './modules/auth/composables/useAuth'

export default {
  setup() {

    const { authStatus, checkAuthStatus } = useAuth()

    checkAuthStatus()

    return {
      authStatus
    }
  }
}*/
</script>
