import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Home from '../App.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../modules/web/view/Home.vue')
  },
  {
    path: '/resultados',
    name: 'resultados',
    component: () => import('../modules/web/view/Resultados.vue')
  },
  {
    path: '/eventos',
    name: 'eventos',
    component: () => import('../modules/web/view/Eventos.vue'),
  },
  {
    path: '/eventos/:slug',
    name: 'slug-resultados',
    component: () => import('../modules/web/view/ResultadosSlug.vue'),
    props: (route) => {
      return {
        slug: route.params.slug
      }
    }
  },
  {
    path: '/eventos/:slug/:categoria',
    name: 'slug-resultados-categorias',
    component: () => import('../modules/web/view/ResultadosSlug.vue'),
    props: (route) => {
      return {
        slug: route.params.slug,
        categoria: route.params.categoria
      }
    }
  },
  {
    path: '/eventos/:slug/:sexo/evento-por-sexo',
    name: 'slug-resultados-sexo',
    component: () => import('../modules/web/view/ResultadosSlug.vue'),
    props: (route) => {
      return {
        slug: route.params.slug,
        sexo: route.params.sexo,
      }
    }
  },
  {
    path: '/eventos/:slug/:categoria/:sexo',
    name: 'slug-resultados-categorias-sexo',
    component: () => import('../modules/web/view/ResultadosSlug.vue'),
    props: (route) => {
      return {
        slug: route.params.slug,
        categoria: route.params.categoria,
        sexo: route.params.sexo,
      }
    }
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },

  /*{
    path: '/inscripcion/:token?',
    name: 'Registro',
    component: () => import('../modules/register/view/Registro.vue'),
    props: (route) => {
      return {
        token: route.params.token,
      }
    }
  },
  {
    path: '/inscripcion/:comercio/:token',
    name: 'Comercio',
    component: () => import('../modules/register/view/RegistroComercio.vue'),
    props: (route) => {
      return {
        token: route.params.token,
        comercio: route.params.comercio,
      }
    }
  },
  {
    path: '/dorsal/:token?',
    name: 'dorsalToken',
    component: () => import('../modules/register/view/Dorsal.vue'),
    props: (route) => {
      return {
        token: route.params.token,
      }
    }
  },
  {
    path: '/consultar',
    name: 'Consultar',
    component: () => import('../modules/register/view/Consulta.vue'),
  },
  {
    path: '/como-puedes-inscribirte',
    name: 'ComoPuedesInscribirte',
    component: () => import('../modules/web/view/ComoRegistrarte.vue'),
  },
  {
    path: '/validar/:token',
    name: 'Validar',
    component: () => import('../modules/register/view/Registro.vue'),
    props: (route) => {
      return {
        token: route.params.token,
      }
    }
  },/ * */
  {
    path: '/informacion-de-pagos-por-evento',
    name: 'pagos',
    component: () => import('../modules/web/view/MetodosdePago.vue')
  },


  {
    path: '/politicas-de-privacidad',
    name: 'politicas',
    component: () => import('../modules/web/view/Politicas.vue')
  },
  {
    path: '/terminos-y-condiciones',
    name: 'terminos',
    component: () => import('../modules/web/view/TerminosCondiciones.vue')
  },

 /* {
    path: '/portal/:token?',
    name: 'portal',
    component: () => import('../modules/register/view/Carreras.vue'),
    props: (route) => {
      return {
        token: route.params.token,
      }
    }
  },*/





  { path: "/:pathMatch(.*)*", component: () => import('@/modules/web/view/404Page'), name: 'page404' }

]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      // This ensures that if hash is provided to router.push it works as expected.
      //  & since we have used "behavior: 'smooth'" the browser will slowly come to this hash position.
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  }
})

export default router
