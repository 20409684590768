export const consultarCarreras = (state, data) => {
    state.carrerasAbiertas = data
}
export const consultarCarrerasToken = (state, data) => {
    state.carreraToken = data
}
export const consultarUserToken = (state, data) => {
    state.tokenUser = data
    state.atletas = data.atletas
    state.atletasInscritos = data.atletas
    state.evento = data.carrera
    state.camisas = data.camisas
    state.categorias = data.categorias
    state.pago = data.carrera.pagos

}

export const selectAtleta = (state, atleta) => {
    console.log('mutacion', atleta)

    state.atletaseleccionado = atleta
}
export const deleteAtleta = (state, id) => {
    // for (let i = 0; i < state.atletas.length; i++) {
    //     if (state.atletas[i].id == id) {
    //         state.atletas[i].bloqueado = '1'
    //     }
    // }
    const idx = state.atletas.map(e => e.id).indexOf(id)
    state.atletas[idx].bloqueado = '1'
    //state.atletas = state.atletas.filter(atleta => atleta.id !== id)
}
export const restaurarAtleta = (state, id) => {
    // for (let i = 0; i < state.atletas.length; i++) {
    //     if (state.atletas[i].id == id) {
    //         state.atletas[i].bloqueado = '0'
    //     }
    // }
    const idx = state.atletas.map(e => e.id).indexOf(id)
    state.atletas[idx].bloqueado = '0'
}

export const confirmarPago = (state, id) => {
    const idx = state.atletas.map(e => e.id).indexOf(id)
    state.atletas[idx].validar_pago = '1'


    /*for (let i = 0; i < state.atletas.length; i++) {
        if (state.atletas[i].id == id) {
            state.atletas[i].validar_pago = '1'
        }
    }*/
    //state.atletas = state.atletas.filter(atleta => atleta.id !== id)
}


//categorias
export const mutCategorias = (state, categorias) => {
    state.categorias = categorias
}

export const deleteCategoria = (state, id) => {
    state.categorias = state.categorias.filter(categoria => categoria.id !== id)
}


//franelas

export const mutFranelas = (state, franelas) => {
    state.franelas = franelas
}

export const deleteFranela = (state, id) => {
    state.franelas = state.franelas.filter(franela => franela.id !== id)
}





///vaciar form 
export const vaciar = (state) => {

    state.vaciar = !state.vaciar
    state.atletaseleccionado = {}
}



/*export const setLoading = (state) => {
    state.isLoading = false
}
export const actLoading = (state) => {
    state.isLoading = true
}

export const clearEventos = (state) => {
    state.eventos = []
    // state.eventoscategorias = []
}

export const setEventos = (state, eventos) => {
    state.eventos = [...state.eventos, ...eventos]
    state.isLoading = false
}
export const setEventosCategorias = (state, eventos) => {
    state.eventoscategorias = [...eventos]
    // state.categorias = eventos.categorias
    state.isLoading = false
}
export const setEvento = (state, evento) => {
    state.selectedEvento = evento
    state.isLoading = false
}
export const setEventoCategoriasAtletas = (state, evento) => {
    if (evento.status) {
        state.page404 = true;
    } else {
        const event = evento.evento
        const atletas = evento.atletas
        const categorias = evento.evento.categorias
        state.search = ''
        state.selectedEvento = event
        state.atletas = atletas
        state.categorias = categorias
        state.isLoading = false
    }
}
export const setEventoCategoriasAtletasSexo = (state, evento) => {
    if (evento.status) {
        state.page404 = true;
    } else {

        console.log('----------------- Evento ---------------')
        console.log(evento)
        const event = evento.evento
        const atletas = evento.atletas
        const categorias = evento.evento.categorias
        state.search = ''
        state.selectedEvento = event
        state.atletas = atletas
        state.categorias = categorias
        state.isLoading = false
    }
}
export const setConfig = (state, config) => {
    state.config = config
    state.isLoading = false
}

export const setEventoCategoria = (state, slug) => {

}
export const setSearchName = (state, term) => {
    state.search = term
}


//LOCAL


export const mostrarAtletasPorCategoriaSexo = (state, result) => {
    console.log('resrest: ' + result)
    if (result != '') {
        state.atletasReserva = state.atletas
        state.atletas = [...result]
        console.log('res: ' + 1)
        / *} else if (result == 0) {
            state.atletas = result
            setTimeout(() => {
                //state.atletas = []
            }, 300)
            console.log('res: ' + 2)* /
    } else {
        state.atletas = state.atletasReserva
        console.log('res: ' + 3)
    }
}*/