import { createStore } from 'vuex'

import atletas from '@/modules/web/Store/atletas/atletas.js'
import register from '@/modules/register/store/register/index.js'

const store = createStore({
    modules: {
        atletas,
        register
    }
})

export default store